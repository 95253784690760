@use "@angular/material" as mat;
@include mat.core();

@import url("https://fonts.googleapis.com/css?family=Jost:100,200,300,400,500,600,700&display=swap");

$my-primary: mat.define-palette(mat.$orange-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$custom-typography: mat.define-typography-config(
  $font-family: "Jost",
);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: $custom-typography,
  )
);
@include mat.all-component-themes($my-theme);

@import "./variables.scss";

@include fwts;

.dark {
  @include dark;
}

.light {
  @include light;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  // position: relative;
  font-family: "Jost" !important;
  &.dark {
    background-color: $colb1 !important;
  }
  &.light {
    background-color: $colwb !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 960px !important;
  }
}

#page {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 300px;
}

@media (max-width: 768px) {
  #page {
    padding-bottom: 500px;
  }
}

.topp {
  height: 90px;
}

.bottom {
  height: 50px;
}

button {
  outline: none;
  border: none;
}

input,
select,
textarea {
  outline: none;
  border: none;
  width: 100%;
}

.poi {
  cursor: pointer;
}

.aubox {
  display: flex !important;
  align-items: center;
  height: 100vh;
}

@media only screen and (max-width: 1200px) {
  html,
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  html,
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 290px) {
  html,
  body {
    font-size: 10px;
  }
}
